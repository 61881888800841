<template>
  <div class="traffic">
    <div class="nav" style="height: 100%">
      <Carousel show="1" />
    </div>
    <div class="navs" ref="navs">
      <div class="navcon">
        <p class="nav_p">
          <span
            :style="{
              background: show == 'deliciousyt' ? '#197CC3' : '#fff',
              color: show == 'deliciousyt' ? '#fff' : '#000',
              zIndex: show == 'deliciousyt' ? '5' : '',
            }"
            @click="gopage('deliciousyt', $event, 'DeliciousYantai')"
            >{{ $t("language.DeliciousYantai") }} <b>|</b>
          </span>
          <span
            :style="{
              background: show == 'development' ? '#197CC3' : '#fff',
              color: show == 'development' ? '#fff' : '#000',
              zIndex: show == 'development' ? '5' : '',
            }"
            @click="gopage('development', $event, 'DynamicDevelopmentZone')"
            >{{ $t("language.DynamicDevelopmentZone") }} <b>|</b></span
          >
          <span
            :style="{
              background: show == 'introduce' ? '#197CC3' : '#fff',
              color: show == 'introduce' ? '#fff' : '#000',
              zIndex: show == 'introduce' ? '5' : '',
            }"
            @click="gopage('introduce', $event, 'CompanyIntroduction')"
            >{{ $t("language.CompanyIntroduction") }} <b>|</b></span
          >
          <span
            :style="{
              background: show == 'contact' ? '#197CC3' : '#fff',
              color: show == 'contact' ? '#fff' : '#000',
              zIndex: show == 'contact' ? '5' : '',
            }"
            @click="gopage('contact', $event, 'ContactUs')"
            >{{ $t("language.ContactUs") }}</span
          >
        </p>
        <p>
          <van-icon color="#999999" name="wap-home" size="20" />
          <span
            >{{ $t("language.current") }} : {{ $t("language.HOMEpage") }}

            >
            <span
              v-html="$t('language.abloutwe')"
              class="spans"
            ></span>
            > {{ $t(title) }}
          </span>
        </p>
      </div>
    </div>
    <div class="content" v-if="show == 'contact'">
      <Contact />
    </div>
    <div class="content" v-else-if="show == 'introduce'">
      <Introduce />
    </div>
    <div class="content" v-else-if="show == 'development'">
      <Development />
    </div>
    <div class="content" v-else-if="show == 'deliciousyt'">
      <Deliciousyt />
    </div>
    <div class="bot">
      <Bottom />
    </div>
  </div>
</template>

<script>
import Contact from "../../components/ourselves/contact.vue";
import Deliciousyt from "../../components/ourselves/deliciousyt.vue";
import Development from "../../components/ourselves/development.vue";
import Introduce from "../../components/ourselves/introduce.vue";
import Carousel from "../../components/pc-carousel.vue";
import Bottom from "../../components/bottom.vue";
export default {
  components: {
    Carousel,
    Bottom,
    Contact,
    Deliciousyt,
    Development,
    Introduce,
  },
  data() {
    return {
      show: "",
      title: "鲜美烟台",
    };
  },
  created() {
    this.showcomponent();
  },
  mounted() {
    document.title = "八角湾";
    let traffic = document.getElementsByClassName("traffic")[0]; // eslint-disable-line no-unused-vars
    this.$nextTick(() => {
      setTimeout(() => {
        let targetbox = document.getElementsByClassName("navs")[0];

        traffic.scrollTop = targetbox.offsetTop;
      });
    });
  },
  methods: {
    showcomponent() {
      if (this.$route.query.path == "鲜美烟台") {
        this.show = "deliciousyt";
        this.title = "language.DeliciousYantai";
      } else if (this.$route.query.path == "活力开发区") {
        this.show = "development";
        this.title = "language.DynamicDevelopmentZone";
      } else if (this.$route.query.path == "公司介绍") {
        this.show = "introduce";
        this.title = "language.CompanyIntroduction";
      } else if (this.$route.query.path == "联系我们") {
        this.show = "contact";
        this.title = "language.ContactUs";
      } else if (this.$route.query.path == "/") {
        this.show = "deliciousyt";
      } else if (this.$route.query.path == "") {
        this.show = "deliciousyt";
      }
    },
    gopage(val, e, title) {
      if (val == "deliciousyt") {
        this.$router.replace({
          path: "/deliciousyt",
          query: { path: `鲜美烟台` },
        });
      } else if (val == "development") {
        this.$router.replace({
          path: "/deliciousyt",
          query: { path: `活力开发区` },
        });
      } else if (val == "introduce") {
        this.$router.replace({
          path: "/deliciousyt",
          query: { path: `公司介绍` },
        });
      } else if (val == "contact") {
        this.$router.replace({
          path: "/deliciousyt",
          query: { path: `联系我们` },
        });
      }
      this.show = val;
      e.target.style.backgroundColor = "#197CC3";
      e.target.style.color = "#fff";
      this.title = "language." + title;
    },
  },
};
</script>

<style scoped>
.spans >>> span {
  font-size: 15px !important;
}
.traffic {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.bot {
  width: 100%;
  
}
.content {
  width: 100%;
  background: #fafafa;
}
.traffic::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.nav {
  width: 100%;
  height: 100%;
}
.navcon {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  height: 70px;
}
.navs {
  width: 1400px;
  margin: 0 auto;
}
.navcon p:nth-of-type(2) {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navconright {
  line-height: 71px;
}
.navcon p {
  font-size: 15px;
  display: inline-block;
  line-height: 70px;
}
.nav_p :hover {
  background-color: #1a7cc3 !important;
  color: white !important;
  cursor: pointer;
  z-index: 5 !important;
}
.nav_p :hover b {
  display: none !important;
}
.navcon p span {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.nav_p span {
  position: relative;
}
.nav_p span b {
  position: absolute;
  z-index: 2 !important;
  right: -4px;
}
.navcon p:nth-of-type(1) {
  height: 100%;
  float: left;
}
.navcon p:nth-of-type(1) span {
  padding: 0 20px;
  float: left;
  text-align: center;
}
.navcon p:nth-of-type(1) span:nth-last-of-type(1) {
  border-right: 0;
}
</style>