<template>
    <div class="introduce">
      <div class="introduce_top">
        <img src="../../assets/image/1628229274(1).png" alt="" />
        <h1>
          {{ $t("language.DynamicDevelopmentZone") }}
          <!-- 活力开发区 -->
        </h1>
        <span>
          <span></span>
          {{ $t("language.Approvedbythe") }}
          <!-- 1984年10月经国务院批准设立，1985年3月开工建设，是全国首批14个国家级开发区之一、中国（山东）自由贸易试验区烟台片区承载地，是烟
          <br>
          台国际招商产业园、中韩产业园、中日产业园和综保区主阵地，山东新旧动能转换核心区。
          <br> 
          先后荣获ISO14000国家示范区、中国工业园区环境管理示范区、全国循环经济试点园区、国家新型工业化示范基地、国家知识产权试点 
          <br> 
          园区、全国模范劳动关系和谐工业园区、联合国绿色工业园区等称号。2020年地区生产总值增长5.5%，固定 
          <br> 
          资产投资增长7%，进出口总额增长14.9%，实际使用外资增长16.6%，一般公共预算收入 
          <br>
          增长5.3%，主要经济指标均高于省市平均水平。 -->
        </span>
      </div>
      <div class="main_conter">
        <div class="introduce_conter">
          <div class="conter_left">
            <!-- <p class="backgroundtext" style="left:50px;top:20px">城岸云浪</p> -->
            <!-- <p class="backgroundtext" style="left:300px;top:120px">海上银贝</p> -->
            <h5>
                 {{ $t("language.WonderfuloctagonBay") }}
                <!-- 精彩八角湾 -->
            </h5>
            <span>
                <!-- 八角湾，一片孕育着希望的热土，“创新”是它的一个“标签”，“文旅会展”也是这片区域的鲜明特色。 -->
               {{ $t("language.BajiaoBayis") }}
               <br />
               <!-- 位于烟台开发区中心位置的八角湾中央创新区，主要打造的是中央创新区。重点布局中央商务区、科技创新区、会展文旅区“三大板块”。八角湾部分区域亦被划入山东自贸试验区烟台片区和中韩(烟台)产业园范围内。 -->
                {{ $t("language.BajiaowanCentral") }}
               <br/>
               <!-- 作为烟台市重点规划建设的九大片区之一，八角湾位于烟台开发区西侧，已成为最具有发展潜力的黄金湾区之一，这一点，从其周边产业布局即可见一斑。八角湾周边聚集了万华、富士康、通用汽车等一批优质产业龙头项目。紧临“三港一站”，距蓬莱国际机场10公里、环渤海高铁烟台西站5.8公里，荣乌高速、206国道穿境而过，北邻烟台港西港区，集区位、自然资源、经济腹地万千优势于一身。 -->
                 {{ $t("language.Asoneofthenine") }}
            </span>
          </div>
          <div class="conter_right">
            <div class="conter_righttop">
              <img src="../../assets/image/八角湾中央创新区.png" alt="" />
            </div>
            <div class="conter_rightbottom">
              <div class="bottom_right">
                <img src="../../assets/image/会展中心-2020218-13.png" alt="" />
              </div>
              <div>
                <img
                  src="../../assets/image/微信图片_20200113153936.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.introduce {
  width: 100%;
  background: #fafafa;
}

       .introduce_top{
        width: 1400px;
        margin: 0 auto;
        height: 900px;
        text-align: center;
        padding-top: 86px;
    }
    .introduce_top h1{
        margin-top: 74px;
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
        margin-bottom: 41px;
    }
    .introduce_top span{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 48px;
    }
    .main_conter{
        width: 100%;
        height:917px;
        background-color: #ffffff;
        padding-top: 104px;
    }
    .introduce_conter{
        width: 1400px;
        height:917px;
        margin: 0 auto;
    }
    .cheched{
        background-color: #1a7cc3;
        color: white;
        cursor: pointer;
    }
    .conter_right{
        float: right;
        width: 632px;
    }
    .conter_right img{
      border-radius: 5%;
    }
    .conter_left{
        float: left;
        width: 691px;
        height: 612px;
        position:relative;
    }
    /* .conter_left :hover{
      overflow: auto;
    } */
    .conter_left h5{
        margin-top: 97px;
        margin-bottom: 20px;
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #197CC3;
        line-height: 100px;
    }
    .conter_left span{
      display: inline-block;
      width: 691px;
      height: 415px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical; 
        /* -webkit-line-clamp:2;   */
         overflow-x: hidden; 
     }
    .conter_righttop img{
        width: 632px;
        height: 330px;
    }
    .conter_righttop{
        margin-bottom: 17px;
    }
    .conter_rightbottom{
        width: 632px;
        /* overflow: hidden; */
    } 
    .conter_rightbottom div{
        float: left;
        width: 305px;
        height: 261px;
    }
    .conter_rightbottom img{
        width: 305px;
        height: 261px;
        
    }
    .bottom_right{
    margin-right: 22px;
    }
    .backgroundtext{
      height: 100px;
      font-size: 80px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #F7F7F7;
      line-height: 100px;
      position:absolute;
    }
    .backgroundtext::-webkit-scrollbar{
        display: none;
    }
</style>