<template>
  <div>
        <div class="introduce">
            <div class="introduce_top">
                <div class="backimg">ABOUT US</div>
                <div class="introduce_left">
                <h1>{{$t('language.DeliciousYantai')}}<!-- 鲜美烟台 --></h1>
                <span>
                    {{$t('language.introduced')}}
                    <!-- 烟台名称源于烟台山,是国务院批复确定的中国山东半岛的中心城市,环海地区重要的港口城市
                    ,是国家历史文化名城2020年,烟台市继续保留全国文明城市荣誉称号,成为全国唯一的“六连
                    冠地城市
                    烟台地处山东半岛中部,东连威海,西接潍坊,西南与青岛邻,北渤海黄海,与宁半岛对
                    ,并与大连海相望,共同形成拱卫首都北京的海上门户
                    烟台是环海济圈胶东经济圈内重要节点城市中国首批14个沿海开放城市之中国海城
                    市,“一带一路”国家战略重点建设港口城市
                    烟台山青水秀,气候人,有丰富的自然景现和人文景现尤其是夏季,海风抚面,清爽可人:山
                    花烂漫,人心;海光山色,交相辉晚,既是避纳凉之胜地,也是各地游客旅游观光的理想填
                    地,市内旅游区众多,有国家5A级旅游景区3处、4A级19处、3A级50处,获“中国优秀城
                    市”称号 -->
                </span>
            </div>
            <div class="introduce_right">
                <img src="../../assets/image/inright.png" alt="">
            </div>
            </div>
            <div class="introduce_bottom">
                <!-- 城市荣誉 -->
                <h1>{{$t('language.honor')}}</h1>
                <ul>
                    <li>
                        <p>
                            {{$t('language.InternationalWineCity')}}
                            <!-- VIO国际葡萄酒城 -->
                        </p>
                        <p>
                            {{$t('language.CityinChina')}}
                            <!-- 最佳中国魅力城市 -->
                        </p>
                        <p>
                            {{$t('language.GardenCity')}}
                            <!-- 国家园林城市 -->
                        </p>
                    </li>
                    <li>
                        <p>
                            {{$t('language.HabitatAward')}}
                            <!-- 联合国人居奖 -->
                        </p>
                        <p>
                            {{$t('language.beautifulCITY')}}
                            <!-- 中国最美丽城市 -->
                        </p>
                        <p>
                            {{$t('language.resortcity')}}
                            <!-- 避暑旅游城市 -->
                        </p>
                    </li>
                    <li>
                        <p>
                            {{$t('language.investmentenvironment')}}
                            <!-- 中国投资环境金牌城市 -->
                        </p>
                        <p>
                            {{$t('language.HealthCity')}}
                            <!-- 国家卫生城市 -->
                        </p>
                        <p>
                            {{$t('language.competitivecity')}}
                            <!-- 中国最具生态竞争力城市 -->
                        </p>
                    </li>
                    <li>
                        <p>
                            {{$t('language.citiesinChina')}}
                            <!-- 中国十大文明城市 -->
                        </p>
                        <p>
                            {{$t('language.ForestCity')}}
                            <!-- 国家森林城市 -->
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bottom_img">
            <img src="../../assets/image/inbottom.png" alt="">
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.backimg{
    width: 1098px;
height: 158px;
font-size: 200px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #F0EFEF;
line-height: 30px;
position: absolute;
top: 134px;
}
 .introduce{
        width:1400px;
        height: 970px;
        margin: 0 auto;
        padding-top: 144px;
        position: relative;
    }
    .introduce_top{
        width: 100%;
        height: 650px;
    }
    .introduce_left{
        float: left;
        width: 690px;
        height: 503px;
        position: absolute;
    }
    .introduce_left :hover{
        overflow: auto;
    }
    .introduce_left h1{
        font-size: 30px;
        line-height: 62px;
    }
    .introduce_left span{
        display: inline-block;
        font-size: 16px;
        line-height: 36px;
        width: 690px;
        height: 441px;
        overflow: hidden;
        text-overflow: ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical; 
        /* -webkit-line-clamp:2;  */
        overflow-x: hidden;
    }
    .introduce_right{
        float: right;
        position: absolute;
        right: 0;
    }
    .introduce_bottom{
        width: 100%;
        height: 320px;
        text-align: center;
        padding-top: 30px;
    }
    .introduce_bottom h1{
        font-size: 30px;
        margin-bottom: 38px;
    }
    .introduce_bottom li{
        float: left;
        width: 345px;
    }
    .introduce_bottom li p{
        text-align: center;
        height: 60px;
        /* line-height: 60px; */
        font-size: 18px;
        font-weight: 600;
    }
    .bottom_img{
        width: 100%;
        height: 100%;
    }
    .bottom_img img{
        width: 100%;
    }
    .cheched{
        background-color: #1a7cc3;
        color: white;
    }
</style>