var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"traffic"},[_c('div',{staticClass:"nav",staticStyle:{"height":"100%"}},[_c('Carousel',{attrs:{"show":"1"}})],1),_c('div',{ref:"navs",staticClass:"navs"},[_c('div',{staticClass:"navcon"},[_c('p',{staticClass:"nav_p"},[_c('span',{style:({
            background: _vm.show == 'deliciousyt' ? '#197CC3' : '#fff',
            color: _vm.show == 'deliciousyt' ? '#fff' : '#000',
            zIndex: _vm.show == 'deliciousyt' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('deliciousyt', $event, 'DeliciousYantai')}}},[_vm._v(_vm._s(_vm.$t("language.DeliciousYantai"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'development' ? '#197CC3' : '#fff',
            color: _vm.show == 'development' ? '#fff' : '#000',
            zIndex: _vm.show == 'development' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('development', $event, 'DynamicDevelopmentZone')}}},[_vm._v(_vm._s(_vm.$t("language.DynamicDevelopmentZone"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'introduce' ? '#197CC3' : '#fff',
            color: _vm.show == 'introduce' ? '#fff' : '#000',
            zIndex: _vm.show == 'introduce' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('introduce', $event, 'CompanyIntroduction')}}},[_vm._v(_vm._s(_vm.$t("language.CompanyIntroduction"))+" "),_c('b',[_vm._v("|")])]),_c('span',{style:({
            background: _vm.show == 'contact' ? '#197CC3' : '#fff',
            color: _vm.show == 'contact' ? '#fff' : '#000',
            zIndex: _vm.show == 'contact' ? '5' : '',
          }),on:{"click":function($event){return _vm.gopage('contact', $event, 'ContactUs')}}},[_vm._v(_vm._s(_vm.$t("language.ContactUs")))])]),_c('p',[_c('van-icon',{attrs:{"color":"#999999","name":"wap-home","size":"20"}}),_c('span',[_vm._v(_vm._s(_vm.$t("language.current"))+" : "+_vm._s(_vm.$t("language.HOMEpage"))+" > "),_c('span',{staticClass:"spans",domProps:{"innerHTML":_vm._s(_vm.$t('language.abloutwe'))}}),_vm._v(" > "+_vm._s(_vm.$t(_vm.title))+" ")])],1)])]),(_vm.show == 'contact')?_c('div',{staticClass:"content"},[_c('Contact')],1):(_vm.show == 'introduce')?_c('div',{staticClass:"content"},[_c('Introduce')],1):(_vm.show == 'development')?_c('div',{staticClass:"content"},[_c('Development')],1):(_vm.show == 'deliciousyt')?_c('div',{staticClass:"content"},[_c('Deliciousyt')],1):_vm._e(),_c('div',{staticClass:"bot"},[_c('Bottom')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }