<template>
        <div class="introduce">
          <div class="introduce_main">
              <div class="maiin_top">
                  <div class="top_left">
                      <img src="../../assets/image/城发1.png" alt="">
                  </div>
                  <div class="top_right">
                     <div>
                         <img src="../../assets/image/集团logo.png" alt="">
                     </div>
                     <div>
                         <span>
                             {{ $t("language.YantaiYedaUrban") }}
                             <!-- 烟台业达城市发展集团有限公司是烟台经济技术开发区区属重点国有企业，组建于2019年9月，注册资本30亿元。截至2020年末，资产总额约265亿元，净资产159亿元，现有员工400余人。是一家集城市更新建设、城市综合运营服务、产业培育和金融服务、会展文旅和海洋经济四大主业为核心的国有资本投资运营平台。 -->
                         </span>
                     </div>
                  </div>
              </div>
              <div class="main_conter">
                  <div class="conter_left">
                      <h5>
                          {{ $t("language.YantaiYeDaWen") }}
                          <!-- 烟台业达文旅有限公司 -->
                      </h5>
                      <span>
                          {{ $t("language.Foundedin2019with") }}
                          <!-- 烟台业达文旅有限公司成立于2019年，注册资本5亿元，是区属国有企业烟台业达城市发展集团有限公司旗下全资子公司。业达文旅集团秉承“让生活更美好”的品牌理念，以提升现代化滨海新城品质为宗旨，繁荣服务业态、拓展海洋经济，深挖文化旅游、留住城市底蕴，致力打造烟台最具吸引力的城市板块，开拓会展文旅新篇章，为建设现代化、靓丽文明的滨海新城助力！ -->
                       </span>
                       <h5>
                        {{ $t("language.YantaiYedaInternational") }}
                        <!-- 烟台业达国际会展有限公司 -->
                       </h5>
                       <span>
                          {{ $t("language.YantaiYedaInternationalExhibitionCo") }}
                          <!-- 烟台业达国际会展有限公司成立于2020年8月，注册资本500万元，是区属国有企业烟台业达城市发展集团有限公司旗下三级全资子公司。业达国际会展有限公司是业达城发集团履行城市运营服务功能的核心企业之一，是烟台八角湾国际会展中心场馆运营商。公司下设营销部、营运部、会议餐饮部、工程物业部、人事行政部及财务部，致力于用国际化的经营理念、先进的管理体系、至臻的服务品质，为参会人员提供集展览、会议、餐饮、休闲、观光购物于一体的极致服务体验。 -->
                      </span>
                  </div>
                  <div class="conter_right">
                      <img src="../../assets/image/A202002001X透视日景@2x.png" alt="">
                  </div>
              </div>
          </div>

        </div>
</template>

<script>
export default {

}
</script>

<style scoped>
   .introduce{
        width:100%;
        height:1500px;
        background: #FAFAFA;
    }
    .introduce_main{
        width: 1400px;
        height:1500px;
        margin: 0 auto;
    }
    .maiin_top{
        padding-top: 89px;
        margin-bottom:65px;
        width: 1400px;
        height: 551px;
        position: relative;
    }
    .top_left img{
        width: 700px;
        height: 618px;
    }
    .top_right{
        box-sizing:border-box;
        text-align: center;
        width: 700px;
        height:618px;
        position: absolute;
        right: 0;
        top:90px;
        /* box-shadow:2px 2px 5px rgba(0,0,0,0.2); */
        padding: 100px 57px 83px 68px;
        background-color: #FFFFFF;
    }
    .top_right span{
        display: inline-block;
        text-align: left;
        width: 400px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
}
    .cheched{
        background-color: #1a7cc3;
        color: white;
    }
    .main_conter{
        width: 1400px;
        height: 551px;
        position: relative;
        margin-bottom: 146px;
    }
    .conter_left{
        box-sizing:border-box;
        width: 700px;
        height: 668px;
        position: absolute;
        left: 0;
        top: 0px;
        background-color: #FFFFFF;
        padding: 0px 52px 30px 100px ;
    }
     .conter_left :hover{
         overflow: auto;
     }
    .conter_left h5{
        background-image: url(../../assets/image/摄图网_401052815_创意中国风红色花纹边框（非企业商用）@2x.png) ;
        background-repeat:no-repeat;
        background-position:135px;
        text-align: center;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #197CC3;
        line-height: 88px;
        width: 540px;
        margin-top: 30px;
    }
    .conter_left p{
        margin-top: 29px;
        margin-bottom: 35px;
        width: 127px;
        height: 3px;
        background: #197CC3;
    }
    .conter_left span{
        display: inline-block;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        width: 540px;
        overflow: hidden;
         text-overflow: ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical; 
        /* -webkit-line-clamp:2;  */
        overflow-x: hidden;

    }
    .conter_right{
        float: right;
       
    }
    .conter_right img{
        width: 700px;
        height: 668px;
    }
</style>