<template>
    <div class="main">
        <div class="title">
            <!-- 联系我们 -->
            <h1>{{$t('language.ContactUs')}}</h1>
        </div>
        <div class="main_img">
            <div class="img_one down">
                <div class="img_bor">
                    <img src="../../assets/image/weizhi.png" alt="">
                </div>
                <div class="describe">
                    <p>{{$t('language.Site')}}</p>
                    <p class="describe_P">{{$t('language.YantaiEconomic')}} </p>
                    <p>{{$t('language.MiddleRoad')}}</p>
                </div>
            </div>
            <div class="down">
                <div class="img_bor">
                    <img src="../../assets/image/电话.png" alt="">
                </div>
                <div class="describe">
                    <p>{{$t('language.Phone')}}</p>
                    <p>0535-6959157</p>
                </div>
            </div>
            <div class="down">
                <div class="img_bor">
                    <img src="../../assets/image/邮箱.png" alt="">
                </div>
                <div class="describe">
                    <p>{{$t('language.Mailbox')}}</p>
                    <p>ytbjw@ybicec.com</p>
                    <p></p>
                </div>
            </div>
            <div>
                <div class="qr_code">
                    <img src="../../assets/image/201628491340_.pic_hd.png" alt="">
                </div>
                <div class="describe last">
                    <p>{{$t('language.WechatQRcode')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.main{
    width: 1400px;
    height: 628px;
    margin: 0 auto;
    text-align: center;
}
.title{
    padding-top:100px ;
}
.title h1{
    font-size: 36px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #333333;

}
.main_img{
    margin-top: 85.8px;
    display: flex;
    justify-content: space-evenly;
}
.img_bor{
width: 136px;
height: 136px;
border: 1px solid #666666;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
margin-top: 13px;
}
/* .describe_P{
    width: 300px;
} */
.qr_code{
    width: 197px;
    height: 198px;
}
.describe{
    padding-top: 35px;
}
.down{
    padding-top: 13px;
}
.describe p{
    font-size: 15px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
line-height: 30px;
}
.img_one{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.last{
    padding-top: 26px;
}
</style>